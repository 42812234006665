<template>
  <div>
    <PageHeader />
    <b-overlay :show="isLoading">
      <b-card v-if="!bankAccountId">
        <BankAccountForm
          v-model="bankData"
          :is-loading="isLoading"
          @submit="onSubmit"
          @close="navigateRoute"
        />
      </b-card>
      <b-card v-if="showOTP">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmitOTP)">
            <ValidationProvider
              v-slot="{ errors }"
              name="otp_code"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="3"
                :label="
                  `กรุณากรอก OTP Ref.(${otpData.otp_reference})`
                "
              >
                <b-form-input
                  v-model="otpData.otp_code"
                  :state="errors[0] ? false : null"
                  :aria-invalid="false"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <div class="text-right">
              <b-button variant="light" class="mr-2" @click="navigateRoute">
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-button variant="dark" class="mr-2" @click="sentOTP">
                Re-send OTP
              </b-button>
              <b-overlay
                :show="isLoading"
                rounded=""
                spinner-small
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary" block>
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoreBankService from '../../../services/bank'

export default {
  page: {
    title: 'ลงทะเบียน True Wallet',
  },
  components: {
    BankAccountForm: () =>
      import('../../../components/forms/bank-truewallet-account-form'),
  },
  data() {
    return {
      isLoading: false,
      bankData: null,
      showOTP: false,
      otpData: {
        otp_code: '',
        otp_reference: '',
        wallet_id: '',
        mobile_number: '',
      },
    }
  },
  computed: {
    ...mapState(['user']),
    bankAccountId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  created() {
    if (this.bankAccountId) {
      this.showOTP = true
      this.otpData.wallet_id = this.bankAccountId
      this.sentOTP()
    }
  },
  methods: {
    navigateRoute() {
      this.$router.push('/bank-truewallets')
    },

    onSubmit(data) {
      if (!this.bankAccountId) {
        this.addBankAccount(data)
      }
    },
    async onSubmitOTP() {
      this.isLoading = true
      try {
        await CoreBankService.submitOTPTruewallet(
          this.otpData.wallet_id,
          this.otpData
        )
        await this.$store.dispatch('notifySuccess', 'created')
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
      this.isLoading = false
    },
    async sentOTP() {
      if (!this.otpData.wallet_id) {
        this.$bvToast.toast('No wallet id found.', {
          variant: 'danger',
          title: 'FAILED',
        })
        return
      }
      this.isLoading = true
      try {
        const otpResponse = await CoreBankService.requestOTPTruewallet(
          this.otpData.wallet_id
        )
        this.showOTP = true
        this.otpData.mobile_number = otpResponse.data.data.mobile_number
        this.otpData.otp_reference = otpResponse.data.data.otp_reference
        this.otpData.otp_code = ''
        this.$bvToast.toast('Please enter OTP.', {
          variant: 'success',
          title: 'SUCCESS',
        })
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
      this.isLoading = false
    },

    async addBankAccount(bankData) {
      this.isLoading = true
      try {
        const res = await CoreBankService.createTruewalletAccount(bankData)
        this.otpData.wallet_id = res.data.id
        await this.sentOTP(res)
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }

      this.isLoading = false
    },
  },
}
</script>
